import { EagleApiInterface, EagleModuleApiInterface } from 'kernel/api/api'

export default (api: EagleApiInterface) => {
  api.collection.productCategoryApi = <EagleModuleApiInterface>{
    tree: (providerId) => api.request({
      url: `/api/provider/${providerId}/product-category/tree`,
      type: 'get',
    }),
    flatTree: (providerId) => api.request({
      url: `/api/provider/${providerId}/product-category/flat-tree`,
      type: 'get',
    }),

  }

  api.collection.productApi = <EagleModuleApiInterface>{
    read: (id: string) => api.request({
      url: `/api/product/${id}`,
      type: 'get',
    }),
    readForEdit: (id: string) => api.request({
      url: `/api/product/read-for-edit/${id}`,
      type: 'get',
    }),
    index: (providerId: string, params = {}) => api.request({
      url: `/api/product/${providerId}/index`,
      type: 'get',
      params,
    }),
    uploadFile: (params: any, name: string) => api.request({
      url: `/api/product/file/upload?name=${name}`,
      type: 'post',
      params,
    }),
    sort: (providerId: string, productId: string, params = {}) => api.request({
      url: `/api/provider/${providerId}/product/${productId}/sort`,
      type: 'patch',
      params,
    }),
    create: (providerId, params = {}) => api.request({
      url: `/api/provider/${providerId}/product`,
      type: 'post',
      params,
    }),
    update: (providerId, id: string, params = {}) => api.request({
      url: `/api/provider/${providerId}/product/${id}/update`,
      type: 'put',
      params,
    }),
    patch: (providerId, id: string, column: string, value: any) => api.request({
      url: `/api/provider/${providerId}/product/${id}`,
      type: 'patch',
      params: {
        column,
        value,
      },
    }),
    delete: (providerId, id: string) => api.request({
      url: `/api/provider/${providerId}/product/${id}`,
      type: 'delete',
    }),
    batch: (providerId, targets: string[], column: string, value: any) => api.request({
      url: `/api/provider/${providerId}/product/batch`,
      type: 'patch',
      params: {
        ids: targets,
        column,
        value,
      },
    }),
    batchDelete: (providerId, targets: string[]) => api.request({
      url: `/api/provider/${providerId}/product/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
    //供應商 - 設定商品優惠類別
    updateDiscountType: (providerId, id: string, params = {}) => api.request({
      url: `/api/provider/${providerId}/product/${id}/discountType`,
      type: 'put',
      params,
    }),
    // 綁定商品分類
    bindMenu: (providerId, productId, categoryIds) => api.request({
      url: `/api/provider/${providerId}/product/${productId}/relational-category`,
      type: 'put',
      params: {
        category_ids: categoryIds,
      },
    }),
    updateUnit: (product_id, params = {}) => api.request({
      // 開啟/關閉出貨單位
      url: `/api/provider/product/${product_id}/open-shipping-unit/update`,
      type: 'put',
      params
    }),
  }

  api.collection.productMenuApi = <EagleModuleApiInterface>{
    read: (providerId, id: string) => api.request({
      url: `/api/provider/${providerId}/product-menu/${id}`,
      type: 'get',
    }),
    index: (providerId, params = {}) => api.request({
      url: `/api/provider/${providerId}/product-menu/index`,
      type: 'get',
      params,
    }),
    create: (providerId, params = {}) => api.request({
      url: `/api/provider/${providerId}/product-menu`,
      type: 'post',
      params,
    }),
    update: (providerId, id: string, params = {}) => api.request({
      url: `/api/provider/${providerId}/product-menu/${id}/update`,
      type: 'put',
      params,
    }),
    patch: (providerId, id: string, column: string, value: any) => api.request({
      url: `/api/provider/${providerId}/product-menu/${id}`,
      type: 'patch',
      params: {
        column,
        value,
      },
    }),
    delete: (providerId, id: string) => api.request({
      url: `/api/provider/${providerId}/product-menu/${id}`,
      type: 'delete',
    }),
    batch: (providerId, targets: string[], column: string, value: any) => api.request({
      url: `/api/provider/${providerId}/product-menu/batch`,
      type: 'patch',
      params: {
        ids: targets,
        column,
        value,
      },
    }),
    batchDelete: (providerId, targets: string[]) => api.request({
      url: `/api/provider/${providerId}/product-menu/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }

  api.collection.productMenuPivotApi = <EagleModuleApiInterface>{
    index: (providerId, id, params = {}) => api.request({
      url: `/api/provider/${providerId}/product-menu-pivot/${id}/index`,
      type: 'get',
      params,
    }),
    create: (providerId, menu_id, params = {}) => api.request({
      url: `/api/provider/${providerId}/product-menu-pivot/${menu_id}`,
      type: 'post',
      params,
    }),
    delete: (providerId, id: string) => api.request({
      url: `/api/provider/${providerId}/product-menu-pivot/${id}`,
      type: 'delete',
    }),
    batchDelete: (providerId, targets: string[]) => api.request({
      url: `/api/provider/${providerId}/product-menu-pivot/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }

  // 商品單位
  api.collection.productUnitApi = <EagleModuleApiInterface>{
    index: (providerId, params = {}) =>
      api.request({
        url: `/api/product/${providerId}/unit`,
        type: "get",
        params,
      }),
    create: (providerId, params = {}) =>
      api.request({
        url: `/api/product/${providerId}/unit`,
        type: "post",
        params,
      }),
    update: (providerId, params = {}) =>
      api.request({
        url: `/api/product/${providerId}/unit`,
        type: "put",
        params,
      }),
    delete: (providerId, params = {}) =>
      api.request({
        url: `/api/product/${providerId}/unit`,
        type: "delete",
        params,
      }),
  };
}
