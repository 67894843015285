<template>
  <div>
    <v-container>
      <div class="d-flex justify-center" v-if="loading">
        檔案匯出中，請稍後⋯⋯
      </div>
      <div class="d-flex justify-center" v-else>
        檔案匯出完成，可以關閉此頁面。
      </div>
    </v-container>
  </div>
</template>

<script>
import XLSX from "xlsx";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";

const BASE_HEIGHT = 18;
export default {
  data: () => ({
    complete: false,
    rawData: [],
    sheetsConfig: [
      {
        label: "module.order",
        data: {
          created_at: {
            label: "data.order.created_at",
            width: 18,
          },
          store_name: {
            label: "data.order.store_name",
            width: 18,
          },
          order_no: {
            label: "data.order.order_no",
            width: 26,
          },
          receive_name: {
            label: "data.order.receive_name",
            width: 26,
          },
          address: {
            label: "data.order.address",
            width: 26,
          },
          phone: {
            label: "data.order.phone",
            width: 26,
          },
          items_product: {
            label: "data.order.items.product",
            width: 26,
          },
          items_count: {
            label: "data.order.items.count",
            width: 26,
          },
          items_shipping_unit: {
            label: "data.order.items.shipping_unit",
            width: 26,
          },
          items_calculate_unit: {
            label: "data.order.items.calculate_unit", // 計價單位
            width: 26,
          },
          items_per_shipping_count: {
            label: "data.order.items.per_shipping_count", // 計價單位/出貨單位(每一個出貨單位包含幾個計價單位)
            width: 26,
          },
          items_price: {
            label: "data.order.items.price", // 單價
            width: 26,
          },
          status: {
            label: "data.order.status", // 訂單狀態
            width: 26,
          },
          delivery_fee: {
            label: "data.order.delivery_fee",
            width: 26,
          },
          logistic_provider: {
            label: "data.order.logistic_provider",
            width: 26,
          },
          logistic_no: {
            label: "data.order.logistic_no",
            width: 26,
          },
          comment: {
            label: "data.order.comment",
            width: 26,
          },
        },
      },
    ],
    loading: true,
  }),
  created() {
    this.startExport();
  },
  methods: {
    resetAll() {
      this.rawData = [];
    },
    handleXlsx(orderNo, content) {
      const sheets = [];
      for (const sheet of this.sheetsConfig) {
        sheets.push(this.handleSheet(sheet));
      }

      try {
        const { file, filename } = this.setupXlsxWorkbook(
          sheets,
          orderNo,
          content
        );
        this.$snotify.success(filename, this.$t("export_excel.successfully"));

        this.resetAll();
        return { file, filename };
      } catch (error) {
        console.error(error);
        this.$snotify.error(
          this.$t("error.unexpect"),
          this.$t("export_excel.failure")
        );

        this.resetAll();
      }
    },
    // 使用aoa匯出Excel
    setupXlsxWorkbook(sheets, orderNo, content) {
      const workbook = XLSX.utils.book_new();

      // 依序處理每個sheet
      for (const sheet of sheets) {
        const xlsxSheet = XLSX.utils.aoa_to_sheet(sheet.aoa);
        xlsxSheet["!rows"] = [];
        xlsxSheet["!cols"] = [];

        for (let i = 1; i <= sheet.aoa.length; i++) {
          xlsxSheet["!rows"].push({ hpx: BASE_HEIGHT });
        }

        for (let i = 1; i <= sheet.columnQuantity; i++) {
          const width = sheet.widthArray[i - 1];
          xlsxSheet["!cols"].push({ wch: width });
        }

        XLSX.utils.book_append_sheet(workbook, xlsxSheet, sheet.sheetLabel);
      }
      let filename = this.filename(orderNo, content);
      if (this.complete) {
        XLSX.writeFile(workbook, `${filename}.xlsx`);
        return { filename };
      } else {
        const file = XLSX.write(workbook, { type: "array", bookType: "xlsx" });
        return { filename: `${filename}.xlsx`, file };
      }
    },
    // 處理每個sheet
    handleSheet(sheet) {
      const sheetLabel = this.$t(sheet.label);
      const aoaHeaders = this.getSheetAoaHeaders(sheet);
      const prependAoa = this.getPrependAoa(sheet);
      const aoa = [...prependAoa, aoaHeaders, ...this.getSheetAoaData(sheet)];
      const widthArray = [];
      for (const dataKey in sheet.data) {
        const dataConfig = sheet.data[dataKey];
        const columnWidth = dataConfig.width || 14;
        widthArray.push(columnWidth);
      }

      return {
        widthArray,
        sheetLabel,
        aoa,
        columnQuantity: aoaHeaders.length,
      };
    },
    // 取得sheet的aoa header之前的aoa
    getPrependAoa(sheet) {
      if (typeof sheet.prependAoa != "function") return [];
      const prependAoa = sheet.prependAoa(this.rawData);
      if (!Array.isArray(prependAoa)) return [];
      return prependAoa;
    },
    // 取得sheet的aoa header
    getSheetAoaHeaders(sheet) {
      const headers = [];
      for (const dataKey in sheet.data) {
        const dataConfig = sheet.data[dataKey];
        headers.push(this.$t(dataConfig.label));
      }
      return headers;
    },
    // 取得sheet的aoa 內容
    getSheetAoaData(sheet) {
      const aoa = [];
      this.iterateRawData((row, index, no) => {
        const aoaRow = [];
        for (const dataKey in sheet.data) {
          const dataConfig = sheet.data[dataKey];
          const cell = this.getCellData(dataKey, dataConfig, row, index, no);
          aoaRow.push(cell);
        }
        aoa.push(aoaRow);
      });
      return aoa;
    },
    cleanupData() {
      this.rawData = [];
    },
    async startExport() {
      const downloadData = {}
      if (this.$route.query.fromRequest) {
        const fromRequest = JSON.parse(decodeURIComponent(this.$route.query.fromRequest))
        this.loading = true
        const results = await this.$api.collection[fromRequest.domain][fromRequest.method](...fromRequest.data)
        downloadData.data = results
        downloadData.complete = 1;
      }
      const processData = {
        data: this.$route.query.fromRequest ? downloadData.data : JSON.parse(decodeURIComponent(this.$route.query.data)),
        complete: this.$route.query.fromRequest ? downloadData.complete: this.$route.query.complete,
        content: this.$route.query.fromRequest ? downloadData.content : this.$route.query.content
      }
      if (!processData.data) {
        return this.$router.replace({ name: "home" });
      }
      this.complete = processData.complete ? true : false;
      const list = processData.data
      this.cleanupData();
      this.loading = true;
      this.content = processData.content ? true : false;
      if (this.complete) {
        try {
          this.setResult(list);
          await this.$nextTick();
          if (this.content) {
            const { filename, file } = this.handleXlsx(
              list.order_no,
              this.content
            );
          } else {
            const { filename, file } = this.handleXlsx();
            saveAs(file, filename);
          }
        } catch (error) {
          // Handle errors from any of the orderNo operations
          console.error("Error during zip file generation", error);
        } finally {
          this.loading = false;
        }
      } else {
        const zip = new JSZip();
        try {
          list.forEach((result) => {
            if (result.order_no) {
              this.setResult(result);
              const { filename, file } = this.handleXlsx(result.order_no);
              zip.file(filename, file);
            }
          });
        } catch (error) {
          // Handle errors from any of the orderNo operations
          console.error("Error during zip file generation", error);
        } finally {
          const content = await zip.generateAsync({ type: "blob" });
          saveAs(
            content,
            `${this.$t("export_excel.filename.picking_list.order")}.zip`
          );

          this.loading = false;
        }
      }
    },
    getData(data) {
      if (Array.isArray(data)) {
        return this.getPickingListCompleteData(data);
      } else {
        return this.getOrderData(data);
      }
    },
    getPickingListCompleteData(data) {
      return data.reduce((completeData, order) => {
        const orderData = this.getOrderData(order);
        completeData.push(...orderData);
        return completeData;
      }, []);
    },
    getOrderData(order) {
      const {
        created_at,
        store_name,
        order_no,
        receive_name,
        address,
        phone,
        comment,
        delivery_fee,
        items,
        status,
        logistic_provider,
        logistic_no,
      } = order;
      if (!items) {
        return [];
      }
      return items.map((item) => {
        const {
          product,
          count,
          shipping_unit,
          shipping,
          calculate_unit,
          per_shipping_count,
          price,
        } = item;
        return {
          created_at,
          store_name,
          order_no,
          receive_name,
          address,
          phone,
          comment,
          delivery_fee,
          status,
          logistic_provider,
          logistic_no,
          items_product: product,
          items_count: count,
          items_shipping_unit: shipping_unit,
          items_shipping: "",
          items_calculate_unit: calculate_unit,
          items_per_shipping_count: per_shipping_count,
          items_price: price,
        };
      });
    },
    setResult(result) {
      this.rawData = this.rawData.concat(this.getData(result));
    },
    // 透過資料設定, row等相關資訊取得每個欄位的資料
    getCellData(dataKey, dataConfig, row, index, no) {
      if (typeof dataConfig.value === "function") {
        return dataConfig.value(row, index, no);
      }

      return window.eagleLodash.get(row, [dataKey]);
    },
    // 迭代資料
    iterateRawData(callback) {
      for (const index in this.rawData) {
        const row = this.rawData[index];
        const no = parseInt(index) + 1;
        callback(row, index, no);
      }
    },
    filename(orderNo, content) {
      if (!orderNo) {
        return `${this.$t("export_excel.filename.picking_list")}`;
      }
      if (content) {
        return `${this.$t(
          "export_excel.filename.picking_list.order"
        )} - ${orderNo}`;
      }
      return `${this.$t(
        "export_excel.filename.picking_list.order"
      )} - ${orderNo}`;
    },
  },
};
</script>

<style lang="sass" type="text/sass" scoped></style>
