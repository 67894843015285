<template>
  <div>
    <v-container pt-2 pb-1>
      <page-title title="訂單管理" />
    </v-container>

    <liff-list :listKey="listKey">
      <v-toolbar
        flat
        dense
        slot="list-data-title"
        class="d-flex justify-end float-right"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" small class="mx-2">
              {{ "action.export" | t }}
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item dense @click="pickingListExportOrder">
              <v-list-item-title>
                {{ "action.picking_list.export.order" | t }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense @click="pickingListExport">
              <v-list-item-title>
                {{ "action.picking_list.export" | t }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense @click="pickingListExportSummary">
              <v-list-item-title>
                {{ "action.picking_list.export.summary" | t }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense @click="pickingListExportAbstract">
              <v-list-item-title>
                {{ "action.picking_list.export.abstract" | t }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </liff-list>
  </div>
</template>

<script lang="babel" type="text/babel">
import _cloneDeep from "lodash/cloneDeep";
import listMixin from "@/components/list/mixins/listMixin.js";
import listConfig from "./orderList";

export default {
  mixins: [listMixin],
  data: () => ({
    listKey: "liff-provider-order-list",
    meta: {},
  }),
  computed: {
    isInLiffClient() {
      return this.$helper.isInLiffClient();
    },
    providerId() {
      return this.$store.getters[`member/providerId`];
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`];
    },
    status() {
      if (!this.listFilter) return null;
      return this.listFilter.status;
    },
    title() {
      if (!this.status) return "";
      return this.$t(`provider.status.${this.status}`);
    },
    batchConfig() {
      return this.$store.getters[`list/${this.listKey}/batchConfig`];
    },
    selected() {
      return this.$store.getters[`list/${this.listKey}/selected`];
    },
    listData() {
      return this.$store.getters[`list/${this.listKey}/data`];
    },
  },
  mounted() {
    this.$root.gaLogEvent("供應商_訂單紀錄頁");
  },
  methods: {
    async pickingListExportOrder() {
      if (!this.selected.length) {
        this.$snotify.error(
          "",
          this.$t("export_excel.error.picking_list.no_selection")
        );
        return;
      }

      const results = await Promise.all(
        this.selected.map(async ({ order_no }) => {
          try {
            return await this.$api.collection.providerApi.pickingListExportOrder(
              this.providerId,
              order_no
            );
          } catch (error) {
            console.error(error);
            this.$snotify.error(
              "",
              this.$t("export_excel.error.picking_list.apply_only")
            );
            return;
          }
        })
      );

      let hasResults = false;
      results.forEach((result) => {
        if (result.order_no) {
          hasResults = true;
        }
      });

      if (!hasResults) {
        this.$snotify.error(
          "",
          this.$t("export_excel.error.picking_list.apply_only")
        );
        return;
      }
      // console.log("results", results);
      this.$helper.openExternalBrowser(
        "",
        "/linerp/provider/pickingList/order/download",
        {
          data: encodeURIComponent(JSON.stringify(results)),
        }
      );
    },
    async pickingListExport() {
      const [start_dt, end_dt] = this.indexParams.filter.created_at
        ? this.indexParams.filter.created_at
        : [undefined, undefined];
      if (!start_dt || !end_dt) {
        this.$snotify.error(
          "",
          this.$t("export_excel.error.picking_list.no_dates")
        );
        return;
      }

      try {
        const requestData = [
          this.providerId,
          { start_dt, end_dt },
        ]
        const results = await this.$api.collection.providerApi.pickingListExport(...requestData);
        if (window.liff.isLoggedIn()) {
          this.$helper.openExternalBrowser(
            "",
            "/linerp/provider/pickingList/order/download",
            {
              data: encodeURIComponent(JSON.stringify(results)),
              complete: 1,
            }
          );
        } else {
          const fromRequest = JSON.stringify({
            domain: "providerApi",
            method: "pickingListExport",
            data: requestData
          })
          this.$router.push({ name: 'provider-pickingList-order-download', query: { fromRequest }})
        }
      } catch (error) {
        console.error(error);
        return;
      }
    },
    async pickingListExportSummary() {
      const [start_dt, end_dt] = this.indexParams.filter.created_at
        ? this.indexParams.filter.created_at
        : [this.formatDate(new Date()), this.formatDate(new Date())];

      console.log(start_dt)
      console.log(end_dt)
      
      if (!start_dt || !end_dt) {
        this.$snotify.error(
          "",
          this.$t("export_excel.error.picking_list.no_dates2")
        );
        return;
      }

      try {
        const results = await this.$api.collection.providerApi.pickingListExportSummary(
          this.providerId,
          { start_dt, end_dt }
        );
        if (results.data) {
          window.open(encodeURI(results.data), "_blank");
        } else {
          if (results.length === 0) {
            console.log('無資料')
          }
        }
      } catch (error) {
        console.error(error);
        return;
      }
    },
    async pickingListExportAbstract() {
      const [start_dt, end_dt] = this.indexParams.filter.created_at
        ? this.indexParams.filter.created_at
        : [this.formatDate(new Date()), this.formatDate(new Date())];

      console.log(start_dt)
      console.log(end_dt)

      if (!start_dt || !end_dt) {
        this.$snotify.error(
          "",
          this.$t("export_excel.error.picking_list.no_dates3")
        );
        return;
      }

      try {
        const results = await this.$api.collection.providerApi.pickingListExportAbstract(
          this.providerId,
          { start_dt, end_dt }
        );
        if (results.data) {
          window.open(encodeURI(results.data), "_blank");
        } else {
          if (results.length === 0) {
            console.log('無資料')
          }
        }
      } catch (error) {
        console.error(error);
        return;
      }
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    async beforeIndex() {
      await Promise.all([]);
    },
    async indexApi(params) {
      return await this.$api.collection.providerApi.getOrders(
        this.providerId,
        params
      );
    },
    getListConfig() {
      return listConfig;
    },
    async batchApi(targets, column, value) {
      if (["apply", "sended"].includes(value)) {
        const allowStatus = {
          apply: "created",
          sended: "apply",
        };
        const selectedIdArr = _cloneDeep(targets);
        const selectedDataArr = this.listData
          .filter(
            (v) =>
              selectedIdArr.includes(v.order_no) &&
              v.status === allowStatus[value]
          )
          .map((v) => {
            return v.order_no;
          });
        if (selectedDataArr.length !== selectedIdArr.length) {
          this.$snotify.warning("僅能批次接單/出貨");
          if (selectedDataArr.length > 0) {
            await this.$api.collection.providerApi.batchUpdateOrderStatus(
              this.providerId,
              selectedDataArr,
              "status",
              value
            );
          }
          return Promise.reject("error status");
        } else {
          return await this.$api.collection.providerApi.batchUpdateOrderStatus(
            this.providerId,
            targets,
            "status",
            value
          );
        }
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="sass" type="text/sass" scoped></style>
